import slick from "slick-carousel/slick/slick.min.js";
import slickCss from "slick-carousel/slick/slick.css";
import slickTheme from "slick-carousel/slick/slick-theme.css";


export default class SlickSlider {

  /**
   * 初期化
   * @param options
   */
  constructor(options) {
    this.options = $.extend(options);
    this.init();
  }

  /**
   * 各スライダー登録
   */
  init() {
    this.slickGallery();
  }


  slickGallery() {
    if ($('.c-gallery-slider').length < 0) {
      return false;
    }

    if ($('.c-gallery-slider__image').length < 2) {
      $(".c-gallery-slider__thumbnail").addClass("is-simple-none");
    }

    $('.c-gallery-slider__inner').eq(0).slick({
      infinite: true,
      arrow: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 0,
      slidesToShow: 1,
      centerMode: true,
      cssEase: 'linear',
      speed: 8000,
      variableWidth: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      swipe: false,
      swipeToSlide: false,
      touchMove: false,
    });

    $('.c-gallery-slider__inner').eq(1).slick({
      infinite: true,
      arrow: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 0,
      slidesToShow: 1,
      centerMode: true,
      cssEase: 'linear',
      speed: 8000,
      variableWidth: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      swipe: false,
      swipeToSlide: false,
      touchMove: false,
      rtl: true,
    });
  }

  // 複製して作業
  myCode() {
    console.log("Hello World");
  }
}




